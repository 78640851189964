export const environment:{[key: string]: any} = {
  frontendUrl: 'https://staging.shrimpp.de',
  shortnameRedirectUrl: 'https://go2.shrimpp.de',
  backendBaseUrl: 'https://backend_staging.shrimpp.de',
  backendPdfUrl: 'https://backend-pdf_staging.shrimpp.de',
  //backendBaseUrl: 'https://cloud_staging.shrimpp.de',
  dev: true,
  httpDefaultTimeout: 80000,
  idps: [{
    name: 'keycloak',
    loginEndpoint: 'login',
    description: '',
  },{
    name: 'satosa',
    loginEndpoint: 'login_satosa',
    description: '',
  },{
    name: 'local',
    loginEndpoint: '*local',
    description: '',
  }]
}