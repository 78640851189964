import { t } from "i18next"
import { ChangeEvent, useState } from "react"
import { useStore } from "../../contexts/store"
import api from "../../api/api"
import { userPwMaxLength, userPwMinLength } from "../../validationConstantsString"
import { alert }from "../../stores/alertStore"

import { Alert, Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { navigate } from "wouter/use-location"


const ChangeMyPassword = ({handleClose, successNavigationRoute, username, firstLogin}: {handleClose: () => void, successNavigationRoute: string, username: string | null, firstLogin?: boolean}) => {
  const { alertStore, uiStore, sessionStore } = useStore()
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const deviceWidth = uiStore.deviceWidth

  const saveNewPassword = async () => {
    setIsLoading(true)
    const res = await api.changePassword(oldPassword, newPassword)
    if (res && res.status === 200) {
      // emulates navigation for browser password manager
      navigate(successNavigationRoute, {replace: true})
      setOldPassword("")
      setNewPassword("")
      setConfirmNewPassword("")
      handleClose()
      alertStore.push(alert(t('Password changed successfully.'), 'success'))
    }
    else {
      setShowWarning(true)
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    if(firstLogin) {
      setOldPassword("")
      setNewPassword("")
      setConfirmNewPassword("")
      setTimeout(() => {
        sessionStore.logout()
      }, 1000)
    }
    else handleClose()
  }

  const isValidPassword = (password: string) => {
    // check password length
    if(password.length < userPwMinLength) return false
    if(password.length > userPwMaxLength) return false
    return true
  }

  const isValidConfirmPassword = (password: string) => {
    if(newPassword === password && password !== "") return true
    return false
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if(isValidPassword(newPassword) && isValidConfirmPassword(confirmNewPassword) && newPassword && confirmNewPassword && !isLoading) {
      saveNewPassword()
    }
  }

  const isValidSubmit = (isValidPassword(newPassword) && isValidConfirmPassword(confirmNewPassword) && newPassword && confirmNewPassword && !isLoading) ? true : false
  return (
    <form onSubmit={handleSubmit}>
    <Box sx={{display: "grid", overflow: "auto", gridTemplateRows: "min-content auto min-content", height: "100%", padding: "25px"}}>
      <Box sx={{display: "grid", alignContent: "start", padding: "0 10px"}}>
        {/* hidden username for browser password manager */}
        <FormControl sx={{display: "none", margin: "10px 0"}}>
          <OutlinedInput
            autoComplete="username"
            disabled={true}
            id={'username'}
            type="text"
            value={username}
          />
        </FormControl>
        {/* old password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="current-password">{t('Old password')}</InputLabel>
          <OutlinedInput
            autoComplete="current-password"
            id={'current-password'}
            type="password"
            label={t('Old password')}
            required
            value={oldPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {setOldPassword(event.target.value); setShowWarning(false)}}
            endAdornment={
              <InputAdornment position="end">
                {oldPassword &&
                  <CheckIcon color="success" />
                }
              </InputAdornment>
            }
          />
        </FormControl>
        {/* new password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="new-password">{t('New password')}</InputLabel>
          <OutlinedInput
            autoComplete="new-password"
            id={'new-password'}
            type="password"
            label={t('New password')}
            required
            value={newPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {setNewPassword(event.target.value); setShowWarning(false)}}
            endAdornment={
              <InputAdornment position="end">
                {(isValidPassword(newPassword) && newPassword) &&
                  <CheckIcon color="success" />
                }
              </InputAdornment>
            }
          />
        </FormControl>
        {/* confirm new password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="confirm-new-password">{t('Confirm new password')}</InputLabel>
          <OutlinedInput
            autoComplete="new-password"
            id={'confirm-new-password'}
            type="password"
            label={t('Confirm new password')}
            required
            value={confirmNewPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {setConfirmNewPassword(event.target.value); setShowWarning(false)}}
            endAdornment={
              <InputAdornment position="end">
                {(isValidConfirmPassword(confirmNewPassword) && confirmNewPassword) &&
                  <CheckIcon color="success" />
                }
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box sx={{margin: "10px"}}>
        <Box sx={{fontSize: "13px", padding: "4px 10px", textDecoration: isValidPassword(newPassword) ? "line-through" : "", color: isValidPassword(newPassword) ? "#919191" : "#bf3b3b"}}>
          {t('New password has at least {{minLength}} and at most {{maxLength}} characters', {minLength: userPwMinLength, maxLength: userPwMaxLength})}
        </Box>
        <Box sx={{fontSize: "13px", padding: "4px 10px", textDecoration: isValidConfirmPassword(confirmNewPassword) ? "line-through" : "", color: isValidConfirmPassword(confirmNewPassword) ? "#919191" : "#bf3b3b"}}>
          {t('Confirmed password is identical to new password')}
        </Box>
      </Box>
      <Box sx={{ display: "grid", alignSelf: "end" }}>
        {showWarning &&
          <Alert sx={{margin: "20px 0"}} icon={<ErrorOutlineIcon fontSize="inherit" />} severity="error">
            {t('There was an error changing the password')}
          </Alert>
        }
        <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", justifyContent: "end"}}>
          <Button onClick={handleCancel} variant="outlined" sx={{backgroundColor: "white", flex: (deviceWidth === "tiny") ? 1 : ""}}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="contained" sx={{ flex: (deviceWidth === "tiny") ? 1 : "", backgroundColor: isValidSubmit ? "" : "lightgrey", "&:hover": {backgroundColor: isValidSubmit ? "" : "lightgrey"}}}>
            {t('Submit')}
          </Button>
        </Box>
      </Box>
    </Box>
    </form>
  )
}

export default ChangeMyPassword